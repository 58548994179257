export const duemilaventidue = [
    {
        img: "img/corsi/2022/alessandro_zimeo.png",
        name: "Alessandro Zimeo",
    },
    {
        img: "img/corsi/2022/andrea_sellitto.png",
        name: "Andrea Sellitto",
    },
    {
        img: "img/corsi/2022/angela_de_liguori.png",
        name: "Angela De Liguori",
    },
    {
        img: "img/corsi/2022/aniello_robustelli.png",
        name: "Aniello Robustelli",
    },
    {
        img: "img/corsi/2022/chiara_coppola.png",
        name: "Chiara Coppola",
    },
    {
        img: "img/corsi/2022/claudio_moscariello.png",
        name: "Claudio Moscariello",
    },
    {
        img: "img/corsi/2022/fabrizio_mareschi.png",
        name: "Fabrizio Mareschi",
    },
    {
        img: "img/corsi/2022/federico_giuseppe_olino.png",
        name: "Federico Giuseppe Olino",
    },
    {
        img: "img/corsi/2022/giuseppe_ceccola.png",
        name: "Giuseppe Ceccola",
    },
    {
        img: "img/corsi/2022/hassen_ferjani.png",
        name: "Hassen Ferjani",
    },
    {
        img: "img/corsi/2022/karol_belmonte.png",
        name: "Karol Belmonte",
    },
    {
        img: "img/corsi/2022/maria_michela_daniele.png",
        name: "Maria Michela Daniele",
    },
    {
        img: "img/corsi/2022/mauro_crescenzo.png",
        name: "Mauro Crescenzo",
    },
    {
        img: "img/corsi/2022/salvatore_de_filippo.png",
        name: "Salvatore De Filippo",
    },
    {
        img: "img/corsi/2022/vincenzo_tieri.png",
        name: "Vincenzo Tieri",
    },
];

export const duemilaventitre = [
    {
        img: "img/corsi/2023/alessandro_graziano.png",
        name: "Alessandro Graziano",
    },
    {
        img: "img/corsi/2023/alfonso_califano.png",
        name: "Alfonso Califano",
    },
    {
        img: "img/corsi/2023/asya_crescenzo.png",
        name: "Asya Crescenzo",
    },
    {
        img: "img/corsi/2023/francesco_sirica.png",
        name: "Francesco Sirica",
    },
    {
        img: "img/corsi/2023/franco_salvatore.png",
        name: "Salvatore Franco",
    },
    {
        img: "img/corsi/2023/michele_lanzieri.png",
        name: "Michele Lanzieri",
    },
    {
        img: "img/corsi/2023/sabrina_mareschi.png",
        name: "Sabrina Mareschi",
    },
    {
        img: "img/corsi/2023/davide_ferrara.png",
        name: "Davide Ferrara",
    },
    {
        img: "img/corsi/2023/antonio_esposito.png",
        name: "Antonio Esposito",
    },
];