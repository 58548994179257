export const lun = [
   {
       img: "img/staff/michela_karol.png",
       start: "17:00",
       end: "19:00",
       program: "Tropp Dj",
       speakers: "Maria Michela Daniele & Karol Belmonte"
   },
   {
        img: "img/staff/alex_francesco.png",
        start: "19:00",
        end: "20:00",
        program: "Troppo Fighi",
        speakers: "Alex Sica & Francesco Papalino"
    },
    {
        img: "img/staff/donatella_maria_martini.png",
        start: "20:00",
        end: "21:00",
        program: "Tropp Sport",
        speakers: "Donatella Maria Martini"
    },
];

export const mar = [
   {
        img: "img/staff/francesco_chiara.png",
        start: "17:00",
        end: "19:00",
        program: "Tropp Dj",
        speakers: "Francesco Ingiustra & Chiara Coppola"
    },
    {
        img: "img/staff/alex_francesco.png",
        start: "19:00",
        end: "20:00",
        program: "Troppo Fighi",
        speakers: "Alex Sica & Francesco Papalino"
    },

];

export const mer = [
   {
        img: "img/staff/fabrizio_karol.png",
        start: "17:00",
        end: "19:00",
        program: "Tropp Dj",
        speakers: "Fabrizio Mareschi & Karol Belmonte"
    },
    {
        img: "img/staff/alex_francesco.png",
        start: "19:00",
        end: "20:00",
        program: "Troppo Fighi",
        speakers: "Alex Sica & Francesco Papalino"
    },
];

export const gio = [
    {
        img: "img/staff/michela_fabrizio.png",
        start: "17:00",
        end: "19:00",
        program: "Tropp Dj",
        speakers: "Michela Maria Daniele & Fabrizio Mareschi"
    },
    {
        img: "img/staff/alex_francesco.png",
        start: "19:00",
        end: "20:00",
        program: "Troppo Fighi",
        speakers: "Alex Sica & Francesco Papalino"
    },
];

export const ven = [
   {
        img: "img/staff/vincenzo_angela.png",
        start: "17:00",
        end: "19:00",
        program: "Tropp Dj",
        speakers: "Vincenzo Tieri & Angela De Liguori"
    },
    {
        img: "img/staff/alex_francesco.png",
        start: "19:00",
        end: "20:00",
        program: "Troppo Fighi",
        speakers: "Alex Sica & Francesco Papalino"
    },

        {
        img: "img/staff/alessio_simini.png",
        start: "20:00",
        end: "21:00",
        program: "L'Anime De La Radio Tua",
        speakers: "Alessio Simini"
    },
];

export const sab = [
   {
       img: "img/staff/eduardo_michele.png",
       start: "09:00",
       end: "11:00",
       program: "Sportiamo",
       speakers: "Eduardo Castaldo & Michele Del Giudice"
   },
   {
        img: "img/staff/giancarlo_antonio.png",
        start: "11:00",
        end: "12:00",
        program: "Blue Sky Saturday",
        speakers: "Giancarlo Scoppetta & Antonio Duca"
    },
];

export const dom = [

];