export const staff = [
    {
        img: "img/staff/eduardo_castaldo.png",
        name: "Eduardo Castaldo",
        roles: "Editore, Station Manager & Speaker",
    },
    {
        img: "img/staff/simone_buscaino.png",
        name: "Simone Buscaino",
        roles: "Station Manager & Web Developer",
    },
];

export const voci = [
    {
        img: "img/staff/eduardo_castaldo.png",
        name: "Eduardo Castaldo",
        roles: "Station Manager & Speaker",
    },
    {
        img: "img/staff/alessio_simini.png",
        name: "Alessio Simini",
        roles: "Speaker",
    },
	{
        img: "img/staff/alex_sica.png",
        name: "Alex Sica",
        roles: "Speaker",
    },
	{
        img: "img/staff/donatella_maria_martini.png",
        name: "Donatella Maria Martini",
        roles: "Speaker",
    },
    {
        img: "img/staff/francesco_papalino.png",
        name: "Francesco Papalino",
        roles: "Speaker",
    },

    {
        img: "img/staff/karol_belmonte.png",
        name: "Karol Belmonte",
        roles: "Speaker",
    },
    {
        img: "img/staff/francesco_ingiustra.png",
        name: "Francesco Ingiustra",
        roles: "Speaker",
    },
    {
        img: "img/staff/chiara_coppola.png",
        name: "Chiara Coppola",
        roles: "Speaker",
    },
    {
        img: "img/staff/fabrizio_mareschi.png",
        name: "Fabrizio Mareschi",
        roles: "Speaker",
    },
    {
        img: "img/staff/maria_michela_daniele.png",
        name: "Maria Michela Daniele",
        roles: "Speaker",
    },
    {
        img: "img/staff/angela_de_liguori.png",
        name: "Angela De Liguori",
        roles: "Speaker",
    },
    {
        img: "img/staff/michele_del_giudice.png",
        name: "Michele Del Giudice",
        roles: "Speaker",
    },
    {
        img: "img/staff/giancarlo_scoppetta.png",
        name: "Giancarlo Scoppetta",
        roles: "Speaker",
    },
    {
        img: "img/staff/antonio_duca.png",
        name: "Antonio Duca",
        roles: "Speaker",
    },
    {
        img: "img/staff/alessandro_graziano.png",
        name: "Alessandro Graziano",
        roles: "Speaker",
    },
    {
        img: "img/staff/michele_lanzieri.png",
        name: "Michele Lanzieri",
        roles: "Speaker",
    },
    {
        img: "img/staff/asya_crescenzo.png",
        name: "Asya Crescenzo",
        roles: "Speaker",
    },
];

export const tecnici = [
    {
        img: "img/staff/simone_buscaino.png",
        name: "Simone Buscaino",
        roles: "Station Manager & Web Developer",
    },
    {
        img: "img/staff/claudio_moscariello.png",
        name: "Claudio Moscariello",
        roles: "Tecnico/Regia",
    },
	{
        img: "img/staff/andrea_sellitto.png",
        name: "Andrea Sellitto",
        roles: "Tecnico/Regia",
    },
	{
        img: "img/staff/giuseppe_ceccola.png",
        name: "Giuseppe Ceccola",
        roles: "Tecnico/Regia",
    },
	{
        img: "img/staff/mauro_crescenzo.png",
        name: "Mauro Crescenzo",
        roles: "Tecnico/Regia",
    },
	{
        img: "img/staff/hassen_ferjani.png",
        name: "Hassen Ferjani",
        roles: "Tecnico/Regia",
    },
	{
        img: "img/staff/aniello_robustelli.png",
        name: "Aniello Robustelli",
        roles: "Tecnico/Regia",
    },
	{
        img: "img/staff/salvatore_de_filippo.png",
        name: "Salvatore De Filippo (DJ Sasi)",
        roles: "Tecnico/Regia & DJ",
    },
	{
        img: "img/staff/federico_giuseppe_olino.png",
        name: "Federico Giuseppe Olino",
        roles: "Tecnico/Regia",
    },
    {
        img: "img/staff/davide_ferrara.png",
        name: "Davide Ferrara",
        roles: "Tecnico/Regia",
    },
    {
        img: "img/staff/franco_salvatore.png",
        name: "Salvatore Franco",
        roles: "Tecnico/Regia",
    },
    {
        img: "img/staff/antonio_esposito.png",
        name: "Antonio Esposito",
        roles: "Tecnico/Regia",
    },
];


export const dj = [
    {
        img: "img/staff/alfonso_califano.png",
        name: "DJ Alfonso Califano",
        roles: "DJ",
    },
	{
        img: "img/staff/mario_dlj.png",
        name: "Mario DLJ",
        roles: "DJ",
    },
	{
        img: "img/staff/tano_dj.png",
        name: "Tano DJ",
        roles: "DJ",
    },
	{
        img: "img/staff/dj_dodo.png",
        name: "DJ Dodo",
        roles: "DJ",
    },
	{
        img: "img/staff/francesco_sirica.png",
        name: "DJ Cicco",
        roles: "DJ",
    },
    {
        img: "img/staff/aleski.png",
        name: "Aleski",
        roles: "DJ",
    },
];